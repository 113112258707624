






































































import { Component, Mixins } from 'vue-property-decorator';
import { ProfileFilter } from 'client-website-ts-library/filters';
import {
  Profile,
  WebsiteProfileDisplayType,
} from 'client-website-ts-library/types';
import { API } from 'client-website-ts-library/services';
import { View } from 'client-website-ts-library/plugins';

import ProfileCards from '../components/ProfileCards.vue';
import Loader from '../components/UI/Loader.vue';

@Component({
  components: {
    ProfileCards,
    Loader,
  },
})
export default class OurPeople extends Mixins(View) {
  private profiles: Profile[] = [];

  mounted() {
    const filter = new ProfileFilter();

    API.Profiles.Search(filter, true).then((profiles) => {
      profiles.push(...[
        {
          Id: '163dfa24-7cf9-478e-b7fa-04cdd255475f',
          FirstName: 'Patrick',
          LastName: 'Richardson',
          Position: 'Director/Principal',
          DisplayPriority: 0,
          DisplayType: 4,
          OfficePhone: '0497 150 839',
          MobilePhone: '0497 150 839',
          Email: 'prichardson@exploreproperty.com.au',
          Website: 'explorepropertyrockhampton.com.au',
          FacebookUrl:
            'https://www.facebook.com/profile.php?id=100091464996128',
          FacebookHandle: '',
          LinkedInUrl: '',
          LinkedInHandle: '',
          TwitterUrl: '',
          TwitterHandle: '',
          RateMyAgentUrl: 'EPR4700QLD',
          RateMyAgentHandle: '',
          InstagramUrl: 'https://www.instagram.com/explorepropertyrockhampton/',
          InstagramHandle: '',
          AboutAgent:
            "<p>Patrick Richardson, the owner and principal of Explore Property Rockhampton, welcomes you to a world of exceptional real estate opportunities.</p><p>With an unwavering commitment to professionalism, Patrick understands your needs and strives to deliver results that exceed your expectations. As your trusted advisor, he will guide you through the intricate world of real estate, empowering you with knowledge and expertise every step of the way.</p><p>Whether you are buying, selling, or investing, Patrick's comprehensive understanding of the market ensures that you make well-informed decisions to maximize your investments. Utilizing a proactive approach, he leverages his extensive network and innovative marketing strategies to bring unmatched value to your real estate journey.</p><p>Trust Patrick Richardson to provide you with unparalleled service and help you achieve your real estate goals with confidence and peace of mind.</p>",
          WebsiteVideoLink: '',
          EmbeddedWebsiteVideo: null as any,
          Photo: {
            Thumbs: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/PatrickRichardson/Agent/Thumbs/65281564-3657-4f96-b1f7-c653a470ff59.png',
            },
            Preview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/PatrickRichardson/Agent/Preview/65281564-3657-4f96-b1f7-c653a470ff59.png',
            },
            Large: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/PatrickRichardson/Agent/Large/65281564-3657-4f96-b1f7-c653a470ff59.png',
            },
            Dynamic:
              '/PhotoLibrary/Explore_Property/Rockhampton/PatrickRichardson/Agent/Large/65281564-3657-4f96-b1f7-c653a470ff59.png',
          },
          PROLISTPhoto: {
            Thumbs: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/PatrickRichardson/Agent/Thumbs/1ad1c048-03ae-4426-86cd-0068580ca50d.png',
            },
            Preview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/PatrickRichardson/Agent/Preview/1ad1c048-03ae-4426-86cd-0068580ca50d.png',
            },
            Large: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/PatrickRichardson/Agent/Large/1ad1c048-03ae-4426-86cd-0068580ca50d.png',
            },
            Dynamic:
              '/PhotoLibrary/Explore_Property/Rockhampton/PatrickRichardson/Agent/Large/1ad1c048-03ae-4426-86cd-0068580ca50d.png',
          },
          CoverPhoto: {
            Thumbs: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/PatrickRichardson/Agent/Thumbs/3201fa3c-c276-499d-93a6-b7b96c18a0cb.png',
            },
            Preview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/PatrickRichardson/Agent/Preview/3201fa3c-c276-499d-93a6-b7b96c18a0cb.png',
            },
            Large: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/PatrickRichardson/Agent/Large/3201fa3c-c276-499d-93a6-b7b96c18a0cb.png',
            },
            Dynamic:
              '/PhotoLibrary/Explore_Property/Rockhampton/PatrickRichardson/Agent/Large/3201fa3c-c276-499d-93a6-b7b96c18a0cb.png',
          },
          Office: {
            Id: 'fd0fc02c-7b22-445b-bc71-9e5580f3e356',
            OfficeName: 'Rockhampton',
            FranchiseName: 'Explore Property',
            Phone: '',
            Email: 'admin.rockhampton@exploreproperty.com.au',
            Fax: '',
            WebsitePhotoMain: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Website/ea8047c7-c5ff-45c1-a803-3e44225377c8.jpg',
            },
            WebsitePhotoPreview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Website/d5c27de3-7ded-460e-bc1f-4e73edae7f32.jpg',
            },
            CompanyName: 'Explore Property Rockhampton and Yeppoon',
            About: '',
            ABN: '',
            DisplayAddress: true,
            AddressLine1: '2/240 Canning Street, ',
            AddressLine2: 'Allenstown QLD 4700',
            POBoxLine1: null as any,
            POBoxLine2: null as any,
            Coordinate: null as any,
            VideoURL: '',
            ExternalLinks: [
              {
                Url: 'https://www.facebook.com/profile.php?id=100091464996128',
                Type: 1,
              },
              {
                Url: 'https://www.instagram.com/explorepropertyrockhampton/',
                Type: 7,
              },
            ],
            EmailAddresses: ['admin.rockhampton@exploreproperty.com.au'],
            OfficeSlug: 'rockhampton',
            FranchiseSlug: 'explore-property',
            FullSlug: 'explore-property-rockhampton',
          },
          FullName: 'Patrick Richardson',
          Slug: 'patrickrichardson',
        },
        {
          Id: '64fc1f77-eea4-46b0-8cbe-e71ffdbe7125',
          FirstName: 'Courtney',
          LastName: 'Klerkx',
          Position: 'Administration',
          DisplayPriority: 0,
          DisplayType: 6,
          OfficePhone: '0497 150 839',
          MobilePhone: '',
          Email: 'admin.rockhampton@exploreproperty.com.au',
          Website: '',
          FacebookUrl: '',
          FacebookHandle: '',
          LinkedInUrl: '',
          LinkedInHandle: '',
          TwitterUrl: '',
          TwitterHandle: '',
          RateMyAgentUrl: 'EPR4700QLD',
          RateMyAgentHandle: '',
          InstagramUrl: '',
          InstagramHandle: '',
          AboutAgent: '',
          WebsiteVideoLink: '',
          EmbeddedWebsiteVideo: null as any,
          Photo: {
            Thumbs: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Courtney.Klerkx/Agent/Thumbs/4387744f-1315-4137-86c7-cacc5ad9209c.png',
            },
            Preview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Courtney.Klerkx/Agent/Preview/4387744f-1315-4137-86c7-cacc5ad9209c.png',
            },
            Large: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Courtney.Klerkx/Agent/Large/4387744f-1315-4137-86c7-cacc5ad9209c.png',
            },
            Dynamic:
              '/PhotoLibrary/Explore_Property/Rockhampton/Courtney.Klerkx/Agent/Large/4387744f-1315-4137-86c7-cacc5ad9209c.png',
          },
          PROLISTPhoto: {
            Thumbs: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Courtney.Klerkx/Agent/Thumbs/da95a256-81d8-4530-95f7-bd6bf2a6925e.png',
            },
            Preview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Courtney.Klerkx/Agent/Preview/da95a256-81d8-4530-95f7-bd6bf2a6925e.png',
            },
            Large: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Courtney.Klerkx/Agent/Large/da95a256-81d8-4530-95f7-bd6bf2a6925e.png',
            },
            Dynamic:
              '/PhotoLibrary/Explore_Property/Rockhampton/Courtney.Klerkx/Agent/Large/da95a256-81d8-4530-95f7-bd6bf2a6925e.png',
          },
          CoverPhoto: null as any,
          Office: {
            Id: 'fd0fc02c-7b22-445b-bc71-9e5580f3e356',
            OfficeName: 'Rockhampton',
            FranchiseName: 'Explore Property',
            Phone: '',
            Email: 'admin.rockhampton@exploreproperty.com.au',
            Fax: '',
            WebsitePhotoMain: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Website/ea8047c7-c5ff-45c1-a803-3e44225377c8.jpg',
            },
            WebsitePhotoPreview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Website/d5c27de3-7ded-460e-bc1f-4e73edae7f32.jpg',
            },
            CompanyName: 'Explore Property Rockhampton and Yeppoon',
            About: '',
            ABN: '',
            DisplayAddress: true,
            AddressLine1: '2/240 Canning Street, ',
            AddressLine2: 'Allenstown QLD 4700',
            POBoxLine1: null as any,
            POBoxLine2: null as any,
            Coordinate: null as any,
            VideoURL: '',
            ExternalLinks: [
              {
                Url: 'https://www.facebook.com/profile.php?id=100091464996128',
                Type: 1,
              },
              {
                Url: 'https://www.instagram.com/explorepropertyrockhampton/',
                Type: 7,
              },
            ],
            EmailAddresses: ['admin.rockhampton@exploreproperty.com.au'],
            OfficeSlug: 'rockhampton',
            FranchiseSlug: 'explore-property',
            FullSlug: 'explore-property-rockhampton',
          },
          FullName: 'Courtney Klerkx',
          Slug: 'courtneyklerkx',
        },
        {
          Id: 'c982685e-488e-4780-ae80-7dc058ff42a9',
          FirstName: 'Maddison',
          LastName: 'Gorman',
          Position: 'Director',
          DisplayPriority: 0,
          DisplayType: 4,
          OfficePhone: '',
          MobilePhone: '',
          Email: 'mgorman@exploreproperty.com.au',
          Website: 'explorepropertyrockhampton.com.au',
          FacebookUrl: 'https://www.facebook.com/profile.php?id=100091464996128',
          FacebookHandle: '',
          LinkedInUrl: '',
          LinkedInHandle: '',
          TwitterUrl: '',
          TwitterHandle: '',
          RateMyAgentUrl: 'EPR4700QLD',
          RateMyAgentHandle: '',
          InstagramUrl: 'https://www.instagram.com/explorepropertyrockhampton/',
          InstagramHandle: '',
          AboutAgent: '<p>Maddison is passionate about helping people realise their property dreams. With a background in marketing and management, Maddison is keen to bring a fresh outlook to the Rockhampton and Capricorn Coast regions.</p>',
          WebsiteVideoLink: '',
          EmbeddedWebsiteVideo: null as any,
          Photo: {
            Thumbs: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/MaddisonGorman/Agent/Thumbs/a1250319-bf2d-4090-8411-3f8ad416aeb1.png',
            },
            Preview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/MaddisonGorman/Agent/Preview/a1250319-bf2d-4090-8411-3f8ad416aeb1.png',
            },
            Large: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/MaddisonGorman/Agent/Large/a1250319-bf2d-4090-8411-3f8ad416aeb1.png',
            },
            Dynamic: '/PhotoLibrary/Explore_Property/Rockhampton/MaddisonGorman/Agent/Large/a1250319-bf2d-4090-8411-3f8ad416aeb1.png',
          },
          PROLISTPhoto: {
            Thumbs: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/MaddisonGorman/Agent/Thumbs/aecfd3ab-39fb-481c-a3da-3a7b841469d9.png',
            },
            Preview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/MaddisonGorman/Agent/Preview/aecfd3ab-39fb-481c-a3da-3a7b841469d9.png',
            },
            Large: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/MaddisonGorman/Agent/Large/aecfd3ab-39fb-481c-a3da-3a7b841469d9.png',
            },
            Dynamic: '/PhotoLibrary/Explore_Property/Rockhampton/MaddisonGorman/Agent/Large/aecfd3ab-39fb-481c-a3da-3a7b841469d9.png',
          },
          CoverPhoto: null,
          Office: {
            Id: 'fd0fc02c-7b22-445b-bc71-9e5580f3e356',
            OfficeName: 'Rockhampton',
            FranchiseName: 'Explore Property',
            Phone: '',
            Email: 'admin.rockhampton@exploreproperty.com.au',
            Fax: '',
            WebsitePhotoMain: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Website/ea8047c7-c5ff-45c1-a803-3e44225377c8.jpg',
            },
            WebsitePhotoPreview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Website/d5c27de3-7ded-460e-bc1f-4e73edae7f32.jpg',
            },
            CompanyName: 'Explore Property Rockhampton and Yeppoon',
            About: '',
            ABN: '',
            DisplayAddress: true,
            AddressLine1: '2/240 Canning Street, ',
            AddressLine2: 'Allenstown QLD 4700',
            POBoxLine1: null as any,
            POBoxLine2: null as any,
            Coordinate: null as any,
            VideoURL: '',
            ExternalLinks: [
              {
                Url: 'https://www.facebook.com/profile.php?id=100091464996128',
                Type: 1,
              },
              {
                Url: 'https://www.instagram.com/explorepropertyrockhampton/',
                Type: 7,
              },
            ],
            EmailAddresses: [
              'admin.rockhampton@exploreproperty.com.au',
            ],
            OfficeSlug: 'rockhampton',
            FranchiseSlug: 'explore-property',
            FullSlug: 'explore-property-rockhampton',
          },
          FullName: 'Maddison Gorman',
          Slug: 'maddisongorman',
        },
        {
          Id: '50ed7973-c673-49c0-b761-f33238f7a10a',
          FirstName: 'Kailah',
          LastName: 'Morriss',
          Position: 'Business Development Manager',
          DisplayPriority: 0,
          DisplayType: 2,
          OfficePhone: '0491 236 788',
          MobilePhone: '0491 236 788',
          Email: 'kmorriss@exploreproperty.com.au',
          Website: '',
          FacebookUrl: '',
          FacebookHandle: '',
          LinkedInUrl: '',
          LinkedInHandle: '',
          TwitterUrl: '',
          TwitterHandle: '',
          RateMyAgentUrl: 'EPR4700QLD',
          RateMyAgentHandle: '',
          InstagramUrl: '',
          InstagramHandle: '',
          AboutAgent:
            '<p>With over 10 years experience in the real estate industry, Kailah knows and understands what it takes to manage your biggest asset. Kailah&nbsp;is a BDM with real experience who knows the ins and outs of all things property management. Contact Kailah today if you want to "keep you investment in safe hands".</p>',
          WebsiteVideoLink: '',
          EmbeddedWebsiteVideo: null as any,
          Photo: {
            Thumbs: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/KailahMorriss/Agent/Thumbs/71a91607-246b-4814-9542-c3d8b6029df2.png',
            },
            Preview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/KailahMorriss/Agent/Preview/71a91607-246b-4814-9542-c3d8b6029df2.png',
            },
            Large: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/KailahMorriss/Agent/Large/71a91607-246b-4814-9542-c3d8b6029df2.png',
            },
            Dynamic:
              '/PhotoLibrary/Explore_Property/Rockhampton/KailahMorriss/Agent/Large/71a91607-246b-4814-9542-c3d8b6029df2.png',
          },
          PROLISTPhoto: {
            Thumbs: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/KailahMorriss/Agent/Thumbs/bd9c2865-3ee7-4239-ae0e-c47442aba23f.png',
            },
            Preview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/KailahMorriss/Agent/Preview/bd9c2865-3ee7-4239-ae0e-c47442aba23f.png',
            },
            Large: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/KailahMorriss/Agent/Large/bd9c2865-3ee7-4239-ae0e-c47442aba23f.png',
            },
            Dynamic:
              '/PhotoLibrary/Explore_Property/Rockhampton/KailahMorriss/Agent/Large/bd9c2865-3ee7-4239-ae0e-c47442aba23f.png',
          },
          CoverPhoto: null as any,
          Office: {
            Id: 'fd0fc02c-7b22-445b-bc71-9e5580f3e356',
            OfficeName: 'Rockhampton',
            FranchiseName: 'Explore Property',
            Phone: '',
            Email: 'admin.rockhampton@exploreproperty.com.au',
            Fax: '',
            WebsitePhotoMain: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Website/ea8047c7-c5ff-45c1-a803-3e44225377c8.jpg',
            },
            WebsitePhotoPreview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Website/d5c27de3-7ded-460e-bc1f-4e73edae7f32.jpg',
            },
            CompanyName: 'Explore Property Rockhampton and Yeppoon',
            About: '',
            ABN: '',
            DisplayAddress: true,
            AddressLine1: '2/240 Canning Street, ',
            AddressLine2: 'Allenstown QLD 4700',
            POBoxLine1: null as any,
            POBoxLine2: null as any,
            Coordinate: null as any,
            VideoURL: '',
            ExternalLinks: [
              {
                Url: 'https://www.facebook.com/profile.php?id=100091464996128',
                Type: 1,
              },
              {
                Url: 'https://www.instagram.com/explorepropertyrockhampton/',
                Type: 7,
              },
            ],
            EmailAddresses: ['admin.rockhampton@exploreproperty.com.au'],
            OfficeSlug: 'rockhampton',
            FranchiseSlug: 'explore-property',
            FullSlug: 'explore-property-rockhampton',
          },
          FullName: 'Kailah Morriss',
          Slug: 'kailahmorriss',
        } as any,
      ]);

      this.profiles = profiles;
    });
  }

  get directors(): Profile[] {
    return this.profiles.filter(
      (p) => p.DisplayType === WebsiteProfileDisplayType.Principal,
    );
  }

  get commercialAgents(): Profile[] {
    return this.profiles.filter(
      (p) => p.DisplayType === WebsiteProfileDisplayType.CommercialAgent,
    );
  }

  get salesAgents(): Profile[] {
    return this.profiles.filter(
      (p) => p.DisplayType === WebsiteProfileDisplayType.Agent,
    );
  }

  get propertyManagers(): Profile[] {
    return this.profiles.filter(
      (p) => p.DisplayType === WebsiteProfileDisplayType.PropertyManager,
    );
  }

  get support(): Profile[] {
    return this.profiles.filter(
      (p) => p.DisplayType === WebsiteProfileDisplayType.PersonalAssistant ||
        p.DisplayType === WebsiteProfileDisplayType.Support,
    );
  }
}
